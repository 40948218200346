import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { ShellComponent } from './_shared-component/layout/shell/shell.component';
import { HeaderComponent } from './_shared-component/layout/header/header.component';
import { SpinnerComponent } from './_shared-component/spinner/spinner.component';
import { SharedModule } from './modules/shared/shared.module';
import { SideNavCompComponent } from './_shared-component/side-nav-comp/side-nav-comp.component';
import { ConfirmDialogueComponent } from './_shared-component/confirm-dialogue/confirm-dialogue.component';
import { NotExistModalComponent } from './_shared-component/not-exist-modal/not-exist-modal.component';
import { SuccessMessageDialogueComponent } from './_shared-component/success-message-dialogue/success-message-dialogue.component';
import { UnsuccessMessageDialogueComponent } from './_shared-component/unsuccess-message-dialogue/unsuccess-message-dialogue.component';
import { SessionTimeoutComponent } from './_shared-component/session-timeout/session-timeout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { SpinnerService } from './_shared-component/spinner/spinner.service';
import { DataContextService } from './_services/datacontext.service';
import { LoggedInUserModulesService } from './_services/login.service';
import { RouterModule, Routes } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { OrderModule } from 'ngx-order-pipe';
import { Broadcaster } from './_services/broadcaste.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { AppConfig } from './_utils/app-config/app-config.service';
import { IMsalConfig } from './_utils/app-config/AppConfig.model';
import { TokenInterceptor } from './_utils/msal/token-interceptor.service';
import { JwtService } from './_services/jwt.service';
import { LaptopInventoryDashboardComponent } from './modules/laptop/laptop-Inventory/laptop-inventory-dashboard/laptop-inventory-dashboard.component';
import { LaptopInventorySearchresultComponent } from './modules/laptop/laptop-Inventory/laptop-inventory-searchresult/laptop-inventory-searchresult.component';
import { LaptopInventorySidenavComponent } from './modules/laptop/laptop-Inventory/laptop-inventory-sidenav/laptop-inventory-sidenav.component';
import { EnvServiceProvider } from './env.service.provider';
import { UnauthorizedComponent } from './modules/shared/unauthorized/unauthorized.component';
import { AuthGuardService } from './_services/auth-guard.service';

let msalConfig: IMsalConfig;
// refer to:
// https://devblogs.microsoft.com/premier-developer/angular-how-to-editable-config-files/
// for a description of the AppConfig service
export function initializeAppConfiguration(appConfig: AppConfig) {
  const promise = appConfig.load().then(() => {
    msalConfig = AppConfig.settings.msal;
  });
  return () => promise;
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: msalConfig.clientId,
      authority: msalConfig.authority,
      redirectUri: msalConfig.redirectUri,
      postLogoutRedirectUri: msalConfig.redirectUri,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: msalConfig.cacheLocation,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = msalConfig.protectedResourceMap

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: msalConfig.scopes
    }
  };
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const routes: Routes = [
  { path:'', component:LandingPageComponent, canActivate: [AuthGuardService]}, 
  { path: 'order', loadChildren: () => import(`./modules/mobile/orders/orders.module`).then(m => m.OrdersModule) },
  { path: 'compliance', loadChildren: () => import(`./modules/mobile/compliance/compliance.module`).then(m => m.ComplianceModule) },
  { path: 'delinquencies', loadChildren: () => import(`./modules/mobile/delinquencies/delinquencies.module`).then(m => m.DelinquenciesModule) },
  { path: 'mbr', loadChildren: () => import('./modules/mobile/monthly-bill-review/mbr.module').then(m => m.MbrModule)},
  { path: 'inventory', loadChildren: () => import('./modules/mobile/inventory/inventory.module').then(m => m.InventoryModule)},
  { path: 'inventoryreconciliation', loadChildren: () => import('./modules/mobile/inventory-reconciliation/inventory-reconciliation.module').then(m => m.InventoryReconciliationModule)},
  { path: 'tnc', loadChildren: () => import('./modules/mobile/terms-and-cancellation/terms-and-cancellation.module').then(m => m.TermsAndCancellationModule)},
  { path: 'admin', loadChildren: () => import('./modules/admin-settings/admin-settings.module').then(m => m.AdminSettingsModule) },
  { path: 'laptop-compliance', loadChildren: () => import('./modules/laptop/laptop-compliance/laptop-compliance.module').then(m => m.LaptopComplianceModule)},
  { path: 'laptop-inventory', loadChildren: () => import('./modules/laptop/laptop-Inventory/laptop-inventory.module').then(m => m.LaptopInventoryModule)},
  { path:'unauthorized',component:UnauthorizedComponent}

];

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    ShellComponent,
    HeaderComponent,
    SpinnerComponent,
    SideNavCompComponent,
    ConfirmDialogueComponent,
    NotExistModalComponent,
    SuccessMessageDialogueComponent,
    UnsuccessMessageDialogueComponent,
    SessionTimeoutComponent,
    UnauthorizedComponent
  ],
  imports: [
    BrowserModule,
    OrderModule,
    NgxPaginationModule,
    FormsModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    MsalModule
    // Ng4LoadingSpinnerModule.forRoot(),
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppConfiguration,
      deps: [AppConfig], multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    JwtService,
    AuthGuardService,
    MsalService,
    MsalGuard,
    MsalBroadcastService, DataContextService,SpinnerService,BnNgIdleService,EnvServiceProvider, LoggedInUserModulesService,Broadcaster],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
